import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"btest"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Components.StrapiProvider collection="my-collection" type="collection">
			<Components.StrapiField
				id="6"
				collection="my-collection"
				fields="myMediaSingle"
				fieldType="image"
				text-align="center"
				size="small"
			/>
			<Components.StrapiField id="1" fields="mytextfield,mynumberfield" fieldType="text" />
			<Components.StrapiField
				id="2"
				collection="my-collection"
				fields="myRichTextJson"
				text-align="center"
				justify-content="flex-end"
				fieldType="richText"
			/>
		</Components.StrapiProvider>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"665a226e1d712a0023345fdf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});